import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import the theme you defined
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginStatusPage from './components/LoginStatusPage';
import ParticipantValidationPage from './components/ParticipantValidationPage';
import './App.css';
import { Box, Typography, Button } from '@mui/material';

const CloseWindowBtn = () => (
  <Box
    sx={{
      position: 'fixed', // Or 'absolute' depending on your layout needs
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center', // Centers the button horizontally
      p: 1, // Adds padding around the box, effectively acting as margin for the button
    }}
  >
    <Button
      variant="outlined"
      onClick={() => window.close()}
      sx={{
        maxWidth: '400px',
        width: '100%', // Makes the button expand to fit the Box, respecting the Box's padding
        fontSize: '1.5em',
        padding: "24px 12px",
      }}
    >
      Chiudi finestra
    </Button>
  </Box>
)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/unlock/:token" element={<LoginStatusPage />} />
          <Route path="/v/:participantCode" element={<ParticipantValidationPage />} />
        </Routes>
      </Router>
      <CloseWindowBtn />
    </ThemeProvider>
  );
}

export default App;

// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff', // Example primary color
    },
    // Define other colors as needed
  },
  typography: {
    // Define typography options here
    fontSize: 14,
    h1: {
        fontSize: '2.25rem', '@media (max-width:600px)': {
            fontSize: '1.5rem', // Smaller font size on smaller screens
          },
    },
    // Define other text styles as needed
  },
  // Include other theme customizations as needed
});

export default theme;
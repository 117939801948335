import React from 'react';
import { Container, Grid, Typography, Box, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/pro-regular-svg-icons'

const Header = ({ event, icon }) => {
  return (
    <>
        {/* Apply the background color to the Container or a Box within it */}
        {/* <Container maxWidth="sm">
            <Box sx={{padding: 2,  backgroundColor:  'rgb(13, 95, 49)', color: 'white', borderRadius: '8px', padding: 2 }}>
                <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <Typography variant="h5" component="h1" textAlign="center">
                    Assemblea Generale 2024
                    </Typography>
                    <Box sx={{ width: '100%', height: '5px', marginTop: 1, backgroundImage: 'linear-gradient(to right, green 33%, white 33%, white 66%, red 66%)' }} />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" component="p" textAlign="center" fontWeight="bold">
                    VENERDI 1 MARZO ORE 17.30
                    </Typography>
                    <Typography variant="body2" component="p" textAlign="center">
                    SALA DISPLAY - BRIXIA FORUM
                    </Typography>
                </Grid>
                </Grid>
            </Box>
        </Container> */}

        {/* Text Box Section */}
        <Container maxWidth="sm" sx={{ mt: 2 }}>
            <Box sx={{
                borderTop: 10,
                borderColor: 'rgb(13, 95, 49)',
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: 2,
                marginBottom: 2,
                textAlign: 'center',
            }}>
                <Typography variant="h4" component="p" sx={{ mt: 2, fontWeight: 'bold' }}>
                    <FontAwesomeIcon size='lg' icon={icon || faQrcode} />
                </Typography>
                <Typography variant="h6" component="p" sx={{ mt: 2 }}>
                    {event?.description}
                </Typography>
                
            </Box>
        </Container>
    </>
  );
};

export default Header;
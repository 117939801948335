const NODE_ENV = process.env.REACT_APP_NODE_ENV || 'development'
const apiUrls = {
	development: 'https://dev-api.confagrisrl.it',
	staging: 'https://dev-api.confagrisrl.it',
	production: 'https://api.confagrisrl.it',
}
const apiUrl = apiUrls[NODE_ENV]

const UNLOCK_DATA_FIELD = 'qr-unlock'

const validateToken = async (token) => {
	try {
		const response = await fetch(apiUrl + '/event/jwt/qr', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		})

		if(!response.ok)
			return false
		const responseData = await response.json()
		return responseData || false
	} catch (error) {
		console.error('Error during form submission:', error)
		return false
	}
}

// Given a token in gives back "true" and save into browser session if valid, otherwise "false"
export const unlock = async (token) => {
	const payload = await validateToken(token)
	if(payload) {
		localStorage.setItem(UNLOCK_DATA_FIELD, JSON.stringify({ token, payload }))
		return true
	}
	else
		return false
}

// If browser is unlocked you get whole token string and its payload, otherwise just a "null" if still locked
export const evalLock = () => {
	const raw = localStorage.getItem(UNLOCK_DATA_FIELD)
	return raw ? JSON.parse(raw) : null
}

// validate [participantCode], if valid you receive an object with event and participant info, otherwise a null
export const validate = async (participantCode) => {
	const scannerToken = evalLock()
	if(!scannerToken)
		return null
	else {
		const response = await fetch(apiUrl + '/event/qr/validate', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + scannerToken.token,
			},
			body: JSON.stringify({
				qrCode: participantCode,
			}),
		})

		if(!response.ok)
			return false
		const responseData = await response.json()
		if(!responseData)
			return false
		return {
			event: scannerToken.payload,
			participant: responseData,
		}
	}
}

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validate } from '../services/api';
import { Box, Typography, Paper, CircularProgress, Container } from '@mui/material';
import Header from './Header';
import { attendOptions, menuOptions, referenceOptions } from '../def'
import { faQuestion, faUserCheck, faUserLock, faUserShield, faUserXmark } from '@fortawesome/pro-regular-svg-icons'

const DrawTerm = ({ label, value, options }) => {
  const displayValue = (options && options.find(option => option.value === value)?.label) || value
  return (
    <Typography variant="body1">
      <strong>{label}</strong>: {displayValue}
    </Typography>
  )
}

const colorsDef = {
  error: {
    border: 'red',
    background: 'rgba(255, 0, 0, 0.1)',
    icon: faUserXmark,
  },
  duplicate: {
    border: 'orange',
    background: 'rgba(255, 165, 0, 0.1)',
    icon: faUserLock,
  },
  ok: {
    border: 'rgb(13, 95, 49)',
    background: 'rgba(13, 95, 49, 0.1)',
    icon: faUserCheck,
  },
  authority: {
    border: 'blue',
    background: 'rgba(0, 0, 200, 0.1)',
    icon: faUserShield,
  },
}

const ParticipantValidationPage = () => {
  const { participantCode } = useParams();
  const [participantInfo, setParticipantInfo] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ colorScheme, setColorScheme ] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchParticipantInfo = async () => {
      const info = await validate(participantCode);
      setIsLoading(false);

      if (info) {
        setParticipantInfo(info);
      } else {
        setError('Impossibile validare il QR o sessione scaduta.');
      }

      setColorScheme(
        !info ?
          'error' :
          (info?.participant?.scans?.length > 1 ? 'duplicate' :
            info?.participant.reference === 'autorità' ? 'authority' : 'ok'
          )
      )
    };
    fetchParticipantInfo();
  }, [participantCode]);

  // const getBorderColor = () => {
  //   if (error) return 'red';
  //   if (participantInfo?.participant.status === 'autorità') return 'blue';
  //   return 'rgb(13, 95, 49)'; // Default for positive but not 'Autorità'
  // };

  // // Apply a lighter shade for the background color
  // const getBackgroundColor = () => {
  //   if (error) return 'rgba(255, 0, 0, 0.1)'; // Lighter red for errors
  //   if (participantInfo?.participant.status === 'autorità') return 'rgba(0, 0, 200, 0.1)'; // Lighter blue for 'Autorità'
  //   return 'rgba(13, 95, 49, 0.1)'; // Lighter green for positive but not 'Autorità'
  // };

  return (
    <>
      <Header icon={colorsDef[colorScheme]?.icon} {...participantInfo} />
      <Container maxWidth="sm">
        <Box>
          <Paper elevation={3} sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            borderColor: colorsDef[colorScheme]?.border,
            borderWidth: '5px',
            borderStyle: 'solid',
            backgroundColor: colorsDef[colorScheme]?.background,
            width: '100%', // Ensure it matches the header width
            boxSizing: 'border-box'
          }}>
            {isLoading ? (
              <CircularProgress sx={{ my: 2 }} />
            ) : error ? (
              <Typography variant="body1" color="error" sx={{ fontWeight: 'bold', my: 2 }}>{error}</Typography>
            ) : (
              participantInfo && (
                <>
                  {/* <Typography variant="h6" component="h2" gutterBottom>
                    Event Details
                  </Typography>
                  <Typography variant="body1">Description: {participantInfo.event.description}</Typography>
                  <Typography variant="body1">Type: {participantInfo.event.type}</Typography> */}

                  {/* <Typography variant="h6" component="h2" sx={{ mt: 2 }} gutterBottom>
                    Participant Details
                  </Typography> */}
                  <DrawTerm label="Azienda" value={participantInfo?.participant?.businessName} />
                  {/* <DrawTerm label="Cod. Fisc." value={participantInfo?.participant?.taxCode} /> */}
                  <DrawTerm label="Persona" value={participantInfo?.participant?.participant} />
                  {/* <DrawTerm label="Tel." value={participantInfo?.participant?.phone} />
                  <DrawTerm label="Email" value={participantInfo?.participant?.email} /> */}
                  <DrawTerm label="Rif" value={participantInfo?.participant?.reference} options={referenceOptions} />
                  <DrawTerm label="Partecipazione" value={participantInfo?.participant?.attend} options={attendOptions} />
                  <DrawTerm label="Menu" value={participantInfo?.participant?.menu} options={menuOptions} />
                  {/* <Typography variant="body1">Status: {participantInfo.participant.status}</Typography> */}
                  {/* <Typography variant="body1">UUID: {participantInfo.participant.uuid}</Typography> */}
                  <DrawTerm label="Posto" value={participantInfo.participant.seat || 'Non assegnato'} />
                  <DrawTerm label="Scansioni eseguite" value={participantInfo.participant.scans?.length || '0'} />
                  { participantInfo.participant.scans?.length && (
                    <DrawTerm label="Prima scansione" value={new Date(participantInfo.participant.scans[0]).toLocaleString('it-IT')} />
                  )}
                </>
              )
            )}
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default ParticipantValidationPage;

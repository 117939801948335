// src/components/LoginStatusPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { unlock } from '../services/api'; // Ensure the path is correct
import { Box, Typography, Button } from '@mui/material';

const LoginStatusPage = () => {
  const { token } = useParams(); // Extract the token from the URL
  const [loginStatus, setLoginStatus] = useState(null);

  useEffect(() => {
    const validateAndUnlock = async () => {
      const isUnlocked = await unlock(token);
      setLoginStatus(isUnlocked ? "Dispositivo abilitato!" : "Dispositivo bloccato");
    };

    if (token) {
      validateAndUnlock();
    }
  }, [token]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
    <Typography variant="h1" textAlign="center" gutterBottom>
      {loginStatus || "Validating..."}
    </Typography>
  </Box>
  );
};

export default LoginStatusPage;
